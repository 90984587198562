import { CompatClient, Stomp } from '@stomp/stompjs';
import SockJS from 'sockjs-client';

class WebSocketManager {
  private client: CompatClient | null = null;
  private isConnected: boolean = false;
  private connectionPromise: Promise<void> | null = null;
  private subscriptions: Map<string, any> = new Map(); // Храним подписки по topic

  connect(url: string, headers: Record<string, string>): Promise<boolean> {
    if (this.client && this.isConnected) {
      console.log('WebSocket connected');
      return Promise.resolve(true); // Уже подключено
    }
  
  
    const socket = new SockJS(url);
    this.client = Stomp.over(socket);
  
    this.connectionPromise = new Promise((resolve, reject) => {
      this.client?.connect(
        headers,
        () => {
          console.log('WebSocket connected');
          this.isConnected = true;
          resolve();
        },
        (error: any) => {
          console.error('WebSocket connection error:', error);
          this.isConnected = false;
          reject(error);
        }
      );
    });
  
    return this.connectionPromise.then(() => true).catch(() => false);
  }

  async subscribe(topic: string, callback: (message: any) => void): Promise<void> {
    if (!this.client) {
      throw new Error('WebSocket client is not initialized. Call connect() first.');
    }

    if (!this.isConnected) {
      console.log('Waiting for WebSocket connection...');
      await this.connectionPromise;
    }

    // Проверим, существует ли уже подписка на данный topic и удалим её
    if (this.subscriptions.has(topic)) {
      const existingSubscription = this.subscriptions.get(topic);
      existingSubscription.unsubscribe();
      console.log(`Unsubscribed from topic: ${topic}`);
    }

    const subscription = this.client.subscribe(topic, callback);
    this.subscriptions.set(topic, subscription);
    console.log(`Subscribed to topic: ${topic}`);
  }

  async send(destination: string, body: any, headers: Record<string, string> = {}): Promise<void> {
    if (!this.client) {
      throw new Error('WebSocket client is not initialized. Call connect() first.');
    }

    if (!this.isConnected) {
      console.log('Waiting for WebSocket connection...');
      await this.connectionPromise;
    }

    try {
      this.client.send(destination, headers, JSON.stringify(body));
      console.log(`Message sent to ${destination}:`, JSON.stringify(body));
    } catch (error) {
      console.error('Error sending message:', error);
      throw error;
    }
  }

  // Для отключения и отписки
  unsubscribe(topic: string): void {
    if (this.subscriptions.has(topic)) {
      const subscription = this.subscriptions.get(topic);
      subscription.unsubscribe();
      this.subscriptions.delete(topic);
      console.log(`Unsubscribed from topic: ${topic}`);
    } else {
      console.warn(`No subscription found for topic: ${topic}`);
    }
  }

  // Закрытие сокета
  close(): void {
    if (this.client?.connected) {
      this.client.disconnect(() => {
        console.log('WebSocket disconnected');
      });
      this.client = null;
      this.isConnected = false;
    }
  }

  
}

export default new WebSocketManager();
