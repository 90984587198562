import React, { useEffect, useState } from 'react';
import './listMessages.css';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import { addMessage, Message, Message as MessageInt, MessageState } from '../../slices/messagesListSlice';
import WebSocketManager from '../../slices/WebSocketManager';
import { setMessageList } from '../../slices/messagesListSlice';
import MessageC from '../message/message';
import { useGetChatMessagesQuery } from '../../services/chatsApi';
import VideoContainer from '../../components/VideoContaider/videoContainer';


const ListMessages: React.FC = () => {
  /*
  const listInnerRef = useRef<HTMLDivElement>(null); // Реф для отслеживания контейнера
  const [messages, setMessages] = useState<any[]>([]); // Массив сообщений
  const [loading, setLoading] = useState(false); // Флаг загрузки
  const [currPage, setCurrPage] = useState(1); // Текущая страница
  const [hasMore, setHasMore] = useState(true); // Флаг наличия новых сообщений
  const [wasLastList, setWasLastList] = useState(false); // Флаг для последней страницы

  // Эмуляция запроса к серверу
  const fetchMessages = async (page: number) => {
    setLoading(true);
    try {
      const response = await axios.get(
        `https://api.instantwebtools.net/v1/passenger?page=${page}&size=10`
      );

      if (!response.data.data.length) {
        setWasLastList(true); // Если данных нет, установим флаг
      }

      setMessages((prevMessages) => [...prevMessages, ...response.data.data]); // Добавляем новые сообщения
      setCurrPage(page); // Обновляем текущую страницу
    } catch (error) {
      console.error('Ошибка при загрузке сообщений:', error);
    } finally {
      setLoading(false);
    }
  };

  // Обработчик прокрутки
  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (scrollTop + clientHeight === scrollHeight && !loading && hasMore) {
        fetchMessages(currPage + 1); // Загружаем следующую страницу
      }
    }
  };

  // Загружаем начальные сообщения при монтировании компонента
  useEffect(() => {
    fetchMessages(1);
  }, []);

  // Используем useEffect для проверки на конец списка
  useEffect(() => {
    if (!wasLastList && !loading) {
      fetchMessages(currPage); // При изменении страницы, загружаем новые сообщения
    }
  }, [currPage, wasLastList, loading]);
  */

  const messages = useSelector((state: RootState) => state.message);
  const chatid = useSelector((state: RootState) => state.message.chat_id);
  const userid = useSelector((state: RootState) => state.message.user_id);
  const profileData = useSelector((state: RootState) => state.profile);
  const [messageInput, setMessageInput] = useState("")
  const dispatch = useDispatch();
  const [isCallActive, setIsCallActive] = useState(false); // Состояние для звонка


 
  const { data: apiResponse, refetch } = useGetChatMessagesQuery({chatId: chatid});
  
  

  const handleMessageCreated = async (event: Message) => {
    console.log(event)
    refetch()
    dispatch(addMessage(event))
  }

  useEffect(()=> {
   
    if (profileData.id) {
      const topic = `/topic/${profileData.id.toString()}/newMessages`;
  
      WebSocketManager.subscribe(topic, (message: any) => {
        const messEvent: Message = JSON.parse(message.body);
        handleMessageCreated(messEvent);
      });
      return () => {
        WebSocketManager.unsubscribe(topic);
        console.log(`Unsubscribed from ${topic}`);
      };
    }
  }, [profileData.id]);

  useEffect(() =>{
    if (apiResponse && Array.isArray(apiResponse)) {
      console.log("message received");
      refetch();
      // Reverse the apiResponse array
      const reversedMessages = [...apiResponse].reverse();

      dispatch(setMessageList(reversedMessages));
    } else {
      console.log("apiResponse is undefined or not an array");
    }

  },[chatid])

  async function handleSendMessage(): Promise<void> {
    try{
      // Отправка сообщения
      if(messageInput){
        console.log(profileData.id)
        WebSocketManager.send(`/app/chat/${chatid}/sendMessage`, {
          text: messageInput,
          forwardId: null, //может быть null
          is_audio: false, //если это ауидосообщение то ещё должно быть одно вложение типа audios
          attachments: []
        });

      }
     
    } catch (error) {
      console.error('Error:', error);
    }
  }

  const formatDate = (dateString: string): string => {
    const date = new Date(dateString); // Преобразуем строку в объект Date
    const day = date.getDate().toString().padStart(2, '0'); // Делаем день двухзначным
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Месяц (с поправкой на 0-индексацию)
  
    return `${day}.${month}`;
  };

  
  const handleCallClick = () => {
    if(messages.chat_id){
      setIsCallActive(true);
    }
  
  };

  const handleEndCall = () => {
    setIsCallActive(false); // Закрываем компонент звонка
  };


  
  return (
    <div className="list-messages">
        <div className="list-messages__header">
            <div className="list-messages__interlocutor">
              <img className="list-messages__avatar" src="Avatar.png" alt="" />
              <span className="list-messages__name">{}</span>
            </div>
            <button className="list-messages__choice"></button>
            <button className="call" onClick={handleCallClick}>позвонить</button>
        </div>
        <div className="message__wrapper">
        {messages.messages.map((mes) => (
          <MessageC key= {mes.message_id} content={mes.message_text} date ={formatDate(mes.message_date)} isUserMessage = {mes.sender.id == profileData.id} avatarUrl={'http://'+mes.sender.image_url} username={mes.sender.first_name + " "+ mes.sender.last_name[0]} />
        ))}
        </div>
        <div className="list-messages__footer">
          <button className="list-messages__upload"></button>
          <input className="list-messages__input" type="text" placeholder="Введите сообщение..." value={messageInput} onChange={(e) => setMessageInput(e.target.value)}/>
          <button className="list-messages__send" onClick={handleSendMessage}></button>
        </div>
        {isCallActive && messages.chat_id!= "" && <VideoContainer  chat_Id={chatid} />}
    </div>
  );
};

export default ListMessages;


