import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AuthState {
  accessToken: string | null;
  accessTokenExpiration: string | null;
  refreshToken: string | null;
  refreshTokenExpiration: string | null;
  isAuthenticated: boolean;
  
}

const initialState: AuthState = {
  accessToken: localStorage.getItem('access_token') || null,
  accessTokenExpiration: localStorage.getItem('access_token_expiration') || null,
  refreshToken: localStorage.getItem('refresh_token') || null,
  refreshTokenExpiration: localStorage.getItem('refresh_token_expiration') || null,
  isAuthenticated: !!localStorage.getItem('refresh_token'),
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setTokens: (
      state,
      action: PayloadAction<{
        accessToken: string;
        accessTokenExpiration: string;
        refreshToken: string;
        refreshTokenExpiration: string; 
      }>
    ) => {
      const { accessToken, accessTokenExpiration, refreshToken, refreshTokenExpiration } = action.payload;
      state.accessToken = accessToken;
      state.accessTokenExpiration = accessTokenExpiration;
      state.refreshToken = refreshToken;
      state.refreshTokenExpiration = refreshTokenExpiration;
      state.isAuthenticated = true;

      localStorage.setItem('access_token', accessToken);
      localStorage.setItem('access_token_expiration', accessTokenExpiration);
      localStorage.setItem('refresh_token', refreshToken);
      localStorage.setItem('refresh_token_expiration', refreshTokenExpiration);
    },

    clearTokens: (state) => {
      state.accessToken = null;
      state.accessTokenExpiration = null;
      state.refreshToken = null;
      state.refreshTokenExpiration = null;
      state.isAuthenticated = false;

      localStorage.removeItem('access_token');
      localStorage.removeItem('access_token_expiration');
      localStorage.removeItem('refresh_token');
      localStorage.removeItem('refresh_token_expiration');
    },


  },
});

export const { setTokens, clearTokens } = authSlice.actions;
export default authSlice.reducer;
