import React, { useState, useEffect } from 'react';
import { useGetPrivateChatsQuery } from '../../services/chatsApi'; 
import Chats from './../chats/chats'; 
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import { PrivateContactItemProps } from './../chats/chats';
import { useLazyGetUserProfileByIdQuery } from '../../services/profileApi';
import WebSocketManager from '../../slices/WebSocketManager';
import './chatsList.css';
import { setChatList } from '../../slices/chatListSlice';

interface ChatEvent {
  chat_id: number;
  message: string;
  target_user_id: number;
  type: string;
}

const ChatsList: React.FC = () => {
  const [chats, setChats] = useState<PrivateContactItemProps[]>([]);
  const [pageIndex, setPageIndex] = useState(0); 
  const { data: apiResponse, refetch } = useGetPrivateChatsQuery({ pageIndex, pageSize: 10 });
  const [fetchUserProfile] = useLazyGetUserProfileByIdQuery();
  const profile = useSelector((state: RootState) => state.profile);
  const [loading, setLoading] = useState(false);

  const [user, setUser] = useState<string | null>(null)

  const dispatch = useDispatch();


  useEffect(() => {
    refetch();
  }, [refetch]);

  useEffect(() => {
    if (apiResponse) {
      const mappedChats: PrivateContactItemProps[] = apiResponse.map((chat) => ({
        id: chat.chat_id,
        name: chat.chat_name,
        lastMessage: chat.last_chat_message,
        imageUrl: "http://" + chat.chat_image_url,
        userId: chat.companion_username
      }));
      setChats(mappedChats);
      dispatch(setChatList(apiResponse))
      
    }
  }, [apiResponse]);

  useEffect(() => {
    const handleChatEvent = async (event: ChatEvent) => {
      if (event.type === 'CREATED' && event.target_user_id != null) {
        // Личный чат
        try {
          const user = await fetchUserProfile({ id: event.target_user_id.toString() }).unwrap();
          setUser(event.target_user_id.toString())
          setChats((prevChats) => [
            ...prevChats,
            {
              id: event.chat_id,
              name: user.username,
              lastMessage: null,
              imageUrl: "http://" + user.user_img_200x200_url,
              userId: user.username
            },
          ]);
        } catch (error) {
          console.error("Error fetching user profile:", error);
        }
      } else if (event.type === 'CREATED' && event.target_user_id == null) {
        // Групповой чат
      } else if (event.type === 'DELETED') {
        // Удаление чата
        setChats((prevChats) => prevChats.filter((chat) => chat.id !== event.chat_id));
      }
    };

    if (profile.id) {
      WebSocketManager.subscribe(`/topic/${profile.id}/chat-events`, (message: any) => {
        const chatEvent: ChatEvent = JSON.parse(message.body);
        handleChatEvent(chatEvent);
      });
    }
    return () => {
      WebSocketManager.unsubscribe(`/topic/${profile.id}/chat-events`);
      console.log(`Unsubscribed from topic: ${`/topic/${profile.id}/chat-events`}`);
    };
  }, [profile, fetchUserProfile]);

  const handleScroll = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    const bottom =
      e.currentTarget.scrollHeight === e.currentTarget.scrollTop + e.currentTarget.clientHeight;
    if (bottom && !loading) {
      setLoading(true); 
      setPageIndex((prevPageIndex) => prevPageIndex + 1); 
    }
  };

  return (
    <div className="contacts-list-container" onScroll={handleScroll}>
      <div className="contacts-list">
        {chats.map((chat) => (
          <Chats id={chat.id} imageUrl={chat.imageUrl} name={chat.name} lastMessage={chat.lastMessage} key={chat.id} userId = {user? user : ""}/>
        ))}
      </div>
      {loading && <div>Loading more contacts...</div>} {/* Индикатор загрузки */}
    </div>
  );
};

export default ChatsList;
