import React from 'react';
import  '../message/message.css';

interface MessageProps {
  content: string;
  date: string;
  isUserMessage: boolean;
  avatarUrl: string; 
  username: string;  
}

const MessageC: React.FC<MessageProps> = ({ content, date, isUserMessage, avatarUrl, username }) => {
  const isShortMessage = content.length < 50; // Порог длины для короткого сообщения

  return (
    <div className={`message-item ${isUserMessage ? 'user-message' : 'other-message'}`}>
      <div className="message-header">
        <img src={avatarUrl} alt="Avatar" className="message-avatar" />
        <span className="message-username2">{username}</span>
      </div>
      <div className="message-content">
        <p>{content}</p>
        <div className={`message-date ${isShortMessage ? 'short-message-date' : 'long-message-date'}`}>
          {date}
        </div>
      </div>
    </div>
  );
};

export default MessageC;
