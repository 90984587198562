import React, { useState } from 'react';
import Modal from 'react-modal';
import './createChatModal.css';
import { useCreatePrivateChatMutation } from '../../services/createChatApi';  // Импортируем мутацию из API

interface CreateChatModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const CreateChatModal: React.FC<CreateChatModalProps> = ({ isOpen, onClose }) => {
  const [nickname, setNickname] = useState('');
  const [createChat, { isLoading, error }] = useCreatePrivateChatMutation();  // Используем мутацию для создания чата

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNickname(event.target.value);
  };

  const handleCreateChat = async () => {
    if (nickname) {
      try {
        await createChat({ username: nickname }).unwrap();
        console.log('Чат успешно создан');
        onClose();
      } catch (error: any) {
        if (error.message) {
          alert(`Ошибка: ${error.message}`);
        } else {
          alert('Не удалось создать чат');
        }
      }
    } else {
      console.log('Введите никнейм');
    }
  };
  

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      overlayClassName="chatsOverlay"
      className="chatsContent"
    >
      <h2 className="chat-create__title">Creating a new chat</h2>
      <div className="chat-create__modal-content">
        <input
          type="text"
          className="chat-create__input"
          placeholder="Enter a name"
          value={nickname}
          onChange={handleInputChange}
        />
      </div>

      <div className="edit__button">
        <button className="button-cancel" onClick={onClose}>Cancel</button>
        <button
          className="button-save"
          onClick={handleCreateChat}
          disabled={isLoading}  
        >
          {isLoading ? 'Creating...' : 'Create'}
        </button>
      </div>
      
    </Modal>
  );
};

export default CreateChatModal;
