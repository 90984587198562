import React, { useEffect } from 'react';
import './chats.css';
import { useDeleteChatMutation, useGetChatMessagesQuery } from '../../services/chatsApi';
import { useDispatch } from 'react-redux';
import { setChatID, setMessageList, setUserID } from '../../slices/messagesListSlice';
import { Message } from '../../slices/messagesListSlice';
import { useGetUserProfileByNameQuery } from '../../services/profileApi';

export interface PrivateContactItemProps {
  id: number;
  name: string;
  lastMessage: Message | null;
  imageUrl: string;
  userId: string;
}

const Chats: React.FC<PrivateContactItemProps> = ({ id, imageUrl, name, lastMessage, userId }) => {
  const [deleteChat] = useDeleteChatMutation();
  const { data: apiResponse, refetch } = useGetChatMessagesQuery({ chatId: id.toString() });
  // Вызываем хук всегда, но делаем запрос только если userName не null


  useEffect(() => {
    refetch();
  }, []);

  const handleDelete = () => {
    console.log(id);
    deleteChat({ id: id.toString() });
  };

  const dispatch = useDispatch();
  const handleClick = () => {
    if (apiResponse && Array.isArray(apiResponse)) {
      console.log("message received" + id.toString());
      // Reverse the apiResponse array
      dispatch(setChatID(id.toString()));
      dispatch(setUserID(userId))
    } else {
      console.log("apiResponse is undefined or not an array");
    }
  };

  return (
    <div className="contact-item" onClick={handleClick}>
      <img className="contact-item__image" src={imageUrl} />
      <div className="contact-item__details">
        <div className="contact-item__name">{name}</div>
        <div className="contact-item__last-message">
          <span className="contact-lastmessage"> {lastMessage?.message_text}</span>
        </div>
      </div>
      <button className="contact-item__delete" onClick={handleDelete}></button> {/* Кнопка для открытия модального окна */}
    </div>
  );
};

export default Chats;
