import { error } from "console";
import { UserRegisterData } from "../../services/registerApi";
import { useState } from "react";


export function FormValidation(initialValues: any) {
    const values = initialValues;
    const validateForm = () => {
      let isValid = true;
  
      // Check if firstName exists before validating
      if ('first_name' in values) {
        values.first_name = values.first_name.trim();
        if (/[0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>?]/.test(values.first_name) ||
          values.first_name == "" ||
          !/[A-ZА-Я]./.test(values.first_name) ||
          /\s/.test(values.first_name)) {
            isValid = false;
            alert("Неверное имя пользователя");
            return;
        }
      }
  
      // Repeat similar checks for other fields
      if ('last_name' in values) {
        values.last_name = values.last_name.trim();
        if (/[0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>?]/.test(values.last_name) ||
          values.last_name == "" ||
          !/[A-ZА-Я]./.test(values.last_name) ||
          /\s/.test(values.last_name)) {
          isValid = false;
          alert("Неверная фамилия пользователя");
          return;
        }
      }
  
      if ('email' in values) {
        values.email = values.email.trim();
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(values.email)) {
          isValid = false;
          alert("Неверный формат почты");
          return;
        }
      }
  
      if ('phone_number' in values) {
        values.phone_number = values.phone_number.trim();
        const phoneRegex = /^\+?[0-9]{8,15}$/;
        if (!phoneRegex.test(values.phone_number)) {
          isValid = false;
          alert("Неверный номер телефона");
          return;
        }
      }
  
      if ('password' in values && 'password_confirmation' in values) {
        values.password = values.password.trim();
        values.password_confirmation = values.password_confirmation.trim();
        if (values.password !== values.password_confirmation ||
          values.password == "" ||
          values.password.length < 8 ||
          !/^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>?]).*$/.test(values.password)) {
            isValid = false;
            alert("Пароль должен быть длиной не менее 8 символов, содержать хотя бы одну заглавную букву и один спец. символ. Проверьте, совпадают ли пароли");
            return;
        }
      }
      if ('password' in values && !('password_confirmation' in values)){
        values.password = values.password.trim();
        if (values.password == "" ||
          values.password.length < 8 ||
          !/^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>?]).*$/.test(values.password)) {
            isValid = false;
            alert("Пароль должен быть длиной не менее 8 символов, содержать хотя бы одну заглавную букву и один спец. символ");
            return;
        }
      }
  
  
      if ('username' in values) {
        values.username = values.username.trim();
        if (values.username == "") {
          isValid = false;
          alert("Поле Username должно быть заполнено");
          return;
        }
      }
  
      return isValid;
    };
  
  
  
    return { validateForm };
  }
  