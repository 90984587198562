import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { store } from './store';
import { Provider } from "react-redux";
import Modal from 'react-modal';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

Modal.setAppElement('#root'); // Установите корневой элемент для доступности
root.render(
  <BrowserRouter>
    <Provider store={store}>
      <App  />
    </Provider>
  </BrowserRouter>
);


reportWebVitals();
