import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import { setProfile } from '../../slices/profileSlice';
import { useGetUserProfileQuery } from '../../services/profileApi';
import "./Profile.css";
import Modal from 'react-modal';
import ProfileModal from '../ProfileModal/ProfileModal';

const Profile: React.FC = () => {
  const dispatch = useDispatch();
  const profileData = useSelector((state: RootState) => state.profile);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const { data, refetch } = useGetUserProfileQuery();
  

  useEffect(() => {
    // Принудительно обновляем данные при монтировании
    refetch();
  }, [refetch]);
;
  useEffect(() => {
   
    if (data) {
      const imageUrl = data.user_img_200x200_url;
      const fullImageUrl = imageUrl.startsWith('http') ? imageUrl : `http://${imageUrl}`;
      console.log("пошло  поехало", data.id);
      dispatch(setProfile({
        email: data.email,
        id: data.id,
        username: data.username,
        firstName: data.first_name,
        lastName: data.last_name,
        birthDate: data.birth_date,
        imageUrl: fullImageUrl, 
        isActive: data.is_active,
        lastActiveDate: data.last_active_date,
        phoneNumber: data.phone_number,
      }));
    }
  }, [data, dispatch]);



  const handleOpenModal = (data: any) => {
    console.log('Opening modal with data:', data);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };



  return (
    <div className="Profile">
      <div className="Profile__info">
        <img src={profileData.imageUrl} alt="Profile" className="profile__img" />
        <div className="profile__nickname" onClick={() => handleOpenModal(profileData)}>
        {profileData.username}
        </div>
      </div>
      <div className="profile__icon">
        <img src="IMG_3914.png" alt="Headphones" className="headphones" />
        <img src="IMG_3918.png" alt="Microphone" className="microphone" />
      </div>
      
      <Modal
        isOpen={isModalOpen}
        onRequestClose={handleCloseModal}
        overlayClassName="customOverlay" // Ваш класс для overlay
        className="customContent"       // Ваш класс для модального контента
      >
        <ProfileModal profileData={profileData} onClose={handleCloseModal} />
      </Modal>
    </div>
  );
};

export default Profile;
