import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { AttachmentResponse } from '../components/interfaces';


export const attachmentApi = createApi({

    reducerPath: 'attachmentApi',
    baseQuery: fetchBaseQuery({
      baseUrl: '/api/v1/attachment',
      prepareHeaders: (headers, { getState }) => {
        const token = localStorage.getItem('access_token');
        if (token) {
          headers.set('authorization', `Bearer ${token}`);
        }
        return headers;
      },
    }),

    endpoints: (builder) => ({
      saveUserAvatar: builder.mutation<AttachmentResponse, {form:FormData}>({
        query: ({form} ) => ({
            url: '', 
            method: 'POST',
            body: form
            
        }),
        transformResponse: (response: any) => {
            console.log('Updated API Response:', response);  // Логируем ответ API
            return response;
          },
      }),
      getUserAvatar: builder.query<String, {address: string}>({
        query: (address) => `IMAGES/${address}`,
        transformResponse: (response: any) => {
            console.log('Updated API Response:', response);  // Логируем ответ API
            return response;
          },
      }),
    }),
    
  });

  
export const { useSaveUserAvatarMutation, useGetUserAvatarQuery} = attachmentApi;
