import { configureStore } from '@reduxjs/toolkit';
import { registerApi } from '../services/registerApi';
import { authApi } from '../services/authApi';
import { refreshApi } from '../services/refreshApi';
import { profileApi } from '../services/profileApi';
import authReducer from '../slices/authSlice';
import profileReducer from '../slices/profileSlice';
import chatReducer from '../slices/chatListSlice';
import messageReducer from '../slices/messagesListSlice'

import { attachmentApi } from '../services/attachmentApi';
import { createChatApi } from '../services/createChatApi';  
import { chatApi } from '../services/chatsApi';

export const store = configureStore({
  reducer: {
    [registerApi.reducerPath]: registerApi.reducer,
    [authApi.reducerPath]: authApi.reducer,
    [refreshApi.reducerPath]: refreshApi.reducer,
    [profileApi.reducerPath]: profileApi.reducer,
    [attachmentApi.reducerPath]: attachmentApi.reducer,
    [createChatApi.reducerPath]: createChatApi.reducer, 
    [chatApi.reducerPath]: chatApi.reducer,

    auth: authReducer,
    profile: profileReducer,
    chat: chatReducer,
    message: messageReducer,
 
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      registerApi.middleware,
      authApi.middleware,
      refreshApi.middleware,
      profileApi.middleware,
      attachmentApi.middleware,
      createChatApi.middleware, 
      chatApi.middleware 
    ),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
