import React, { useState } from "react";
import { AppDispatch } from "../../store";
import { Link, useNavigate } from "react-router-dom";
import { useLoginMutation } from "../../services/authApi"; 
import { setTokens, clearTokens } from "../../slices/authSlice"; 
import { useDispatch } from "react-redux";
import "./LoginPage.css";
import { initializeAuth } from "../../thunks/authThunks";
import { refreshApi } from "../../services/refreshApi";
import WebSocketManager from "../../slices/WebSocketManager";

const LoginPage: React.FC = () => {
  const [formData, setFormData] = useState({ username: "", password: "" });
  const navigate = useNavigate();
  const dispatch = useDispatch(); 
  const [login, { isLoading }] = useLoginMutation();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  
  const handleSubmit = async (e: React.FormEvent) => {

    e.preventDefault();
    try {
     
      const result = await login(formData).unwrap();
      console.log("Login successful!", result);
      dispatch(clearTokens());
      dispatch(setTokens({
        accessToken: result.access_token,
        accessTokenExpiration: result.access_token_expiration,
        refreshToken: result.refresh_token,
        refreshTokenExpiration: result.refresh_token_expiration,
      }));
     
      navigate("/"); 
    } catch (err) {
      console.error("Login failed:", err);
      alert("Ошибка авторизации");
    }
  };

  return (
    <div className="login">
      <div className="login__container">
        <div className="login__left"></div>
        <div className="login__right">
          <img src="Dummy_Logo.png" alt="" className="login__img" />
          <h1 className="login__heading">Login into your account</h1>
          <form onSubmit={handleSubmit} className="form">
            <div className="email-input">
              <label htmlFor="email" className="email-input__label">
                Username:
              </label>
              <div className="email-input__container">
                <input
                  type="text"
                  id="username"
                  name="username"
                  className="email-input__field"
                  placeholder="Username"
                  value={formData.username}
                  onChange={handleChange}
                />
                <div className="email-input__icon-wrapper">
                  <img src="Frame.png" alt="email-icon" className="email-input__icon" />
                </div>
              </div>
            </div>
            <div className="password-input">
              <label htmlFor="password" className="password-input__label">
                Password:
              </label>
              <div className="password-input__container">
                <input
                  type="password"
                  id="password"
                  name="password"
                  className="password-input__field"
                  placeholder="Enter password"
                  value={formData.password}
                  onChange={handleChange}
                />
                <div className="password-input__icon-wrapper">
                  <img src="Frame (1).png" alt="password-icon" className="password-input__icon" />
                </div>
              </div>
            </div>
            <h2 className="login__forgot">Forgot password?</h2>
            <button className="login__button" type="submit" disabled={isLoading}>
              {isLoading ? "Logging in..." : "Login now"}
            </button>
          </form>
          <div className="login__line-wrapper">
            <div className="line"></div>
            <div className="login__line-or">OR</div>
            <div className="line"></div>
          </div>
          <Link to="/register" className="signup__button">
            Signup now
          </Link>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
