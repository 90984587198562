import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export interface AuthResponse {
    access_token: string;
    access_token_expiration: string;
    refresh_token: string;
    refresh_token_expiration: string;
  }


export interface UserAuthData {
  username: string;
  password: string;
}

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: fetchBaseQuery({ baseUrl: '/api/v1/auth' }),
  endpoints: (builder) => ({
    login: builder.mutation<AuthResponse, UserAuthData>({
      query: (data) => ({
        url: '/login', 
        method: 'POST',
        body: data,
      }),
    }),
  }),
});

export const { useLoginMutation } = authApi;
