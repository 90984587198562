
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export interface RegisterResponse {
    id: number;
    username: string;
    email: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
  }
  
  
  export interface UserRegisterData {
    username: string;               
    email: string;                  
    password: string;              
    password_confirmation: string;  
    first_name: string;             
    last_name: string;              
    birth_date: string;             
    phone_number: string;           
  }

export const registerApi = createApi({
  reducerPath: 'registerApi',
  baseQuery: fetchBaseQuery({ baseUrl: '/api/v1/auth' }),
  endpoints: (builder) => ({
    register: builder.mutation<RegisterResponse, UserRegisterData>({
      query: (data) => ({
        url: '/register',
        method: 'POST',
        body: data,
      }),
    }),
  }),
});

export const { useRegisterMutation } = registerApi;
