import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Message } from './messagesListSlice';


export interface PrivateChat {
    chat_id: number;         
    chat_name: string;       
    is_group_chat: false;  
    last_chat_message: Message;  
    chat_image_url: string
    companion_username:string
}
  
  export interface GroupChat {
    chat_id: number;         
    chat_name: string;       
    is_group_chat: false;  
    last_chat_message: Message;  
    chat_image_url: string | null
    chat_members: ChatMembers[]
}
  
  export interface ChatMembers {
    id: number
    first_name: string
    last_name: string
    image_url: string
    is_active: boolean
}

export type Chat = PrivateChat | GroupChat;
const initialState:Chat[]= []


const chatSlice = createSlice({
    name: 'chatList',
    initialState,
    reducers:{
        setChatList: (state, action: PayloadAction<Chat[]>) => {
            return action.payload;
        },
        addChat: (state, action: PayloadAction<Chat>) => {
            state.push(action.payload); // Добавляем новый чат
        },
        // Удаление чата
        removeChat: (state, action: PayloadAction<number>) => {
            return state.filter(chat => chat.chat_id !== action.payload); // Удаляем чат по ID
        },
        // Обновление информации о чате
        updateChat: (state, action: PayloadAction<Chat>) => {
            const index = state.findIndex(chat => chat.chat_id === action.payload.chat_id);
            if (index !== -1) {
              state[index] = action.payload; // Обновляем данные чата
            }
        },
    },
});

export const { setChatList, addChat, removeChat, updateChat } = chatSlice.actions;
export default chatSlice.reducer;