import React, { useEffect } from 'react';
import './ProfileModal.css';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import { clearProfile } from '../../slices/profileSlice'; // Импортируем экшен для очистки профиля
import ProfileField from '../profileField/ProfileField';
import { useNavigate } from 'react-router-dom'; // Импортируем хук useNavigate
import { useSaveUserAvatarMutation, useGetUserAvatarQuery } from '../../services/attachmentApi';
import { useUpdateUserProfileFieldsMutation } from '../../services/profileApi';
import { setProfile } from '../../slices/profileSlice';
import { clearTokens } from '../../slices/authSlice';
import WebSocketManager from '../../slices/WebSocketManager';
import { clearList } from '../../slices/messagesListSlice';

interface ProfileDetailsProps {
  profileData: any;
  onClose: () => void;
}

const ProfileModal: React.FC<ProfileDetailsProps> = ({ profileData, onClose }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate(); 
  const [saveUserAvatar] =  useSaveUserAvatarMutation();
  const [updateUserProfileFields] = useUpdateUserProfileFieldsMutation();
  const profileEdit = useSelector((state: RootState) => state.profile);

  useEffect(() =>{
    console.log(`Данные изменены`);
  },[profileEdit])

  const handleLogout = () => {
    dispatch(clearProfile());
    dispatch(clearTokens());
    dispatch(clearList());
    WebSocketManager.close();
    localStorage.clear(); 
    onClose(); 
  
  };
  const handleSaveIMG= async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]; 
    const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));
    console.log(event.target.files?.[0].name);
    if(file){
      const type = "IMAGES"
      const form = new FormData();
      form.append('file', file); // Добавляем файл
      form.append('type', type); // Добавляем тип
      try {
        const response = await saveUserAvatar({ form });
        
        if (response.data) { // Проверяем, есть ли id в data
          console.log( response.data);
          const obj: Record<string, any> = {
            "user_image_id": response.data
          };
          const response2 = await updateUserProfileFields({ apiEnd:"imageId", obj }).unwrap();

          dispatch(setProfile({ ...profileEdit, imageUrl: "http://"+response2.user_img_200x200_url })); 
        } else {
          console.error("Response does not contain 'id'");
        }
      } catch (error) {
        console.error("Error uploading file:", error);
      }
    }
        
      
  
  };
  return (
    <div className="profile-modal">
      <div className="upload-container">
        <label className="upload-button" title="Загрузить файл">
          <input type="file" className="upload-input" onChange={handleSaveIMG}/>
          <div className="image-upload-wrapper">
            <img src={profileEdit.imageUrl} alt="Upload" className="upload-icon" />
            <span className="upload-text">Select a file</span>
          </div>
        </label>
      </div>

      <h1 className="profile__username"> {profileEdit.username}</h1>
      <div className="profile__inf">
        <ProfileField label="Username" field={profileEdit.username} apiEnd='username' apiFieldName='username' />
        <ProfileField label="Name" field={profileEdit.firstName} apiEnd='firstName' apiFieldName='first_name' />
        <ProfileField label="Surname" field={profileEdit.lastName} apiEnd='lastName' apiFieldName='last_name' />
        <ProfileField label="Email" field={profileEdit.email} apiEnd='email' apiFieldName= 'email' />
        <ProfileField label="Phone" field={profileEdit.phoneNumber} apiEnd='phoneNumber' apiFieldName='phone_number' />
        <ProfileField label="Birth date" field={profileEdit.birthDate} apiEnd='birthDate' apiFieldName='birth_date' />
        <ProfileField label="Password" field={"Change password"} apiEnd='password' apiFieldName='old_password'/>
      </div>

      <button className="profile_logout" onClick={handleLogout}>Log out</button>
    </div>
  );
};

export default ProfileModal;
