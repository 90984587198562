import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import { ProfileState, setProfile } from '../../slices/profileSlice';
import Modal from 'react-modal';
import './ProfileField.css';
import { useUpdateUserProfileFieldsMutation } from '../../services/profileApi';



interface ProfileFieldProps {
  label: string;
  field: string;
  apiEnd: string;
  apiFieldName: string
}

const ProfileField: React.FC<ProfileFieldProps> = ({ label, field, apiEnd , apiFieldName}) => {
  const profileData = useSelector((state: RootState) => state.profile);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newValue, setNewValue] = useState(field); 
  const [newPasswordValue, setNewPasswordValue] = useState(field); 
  const [updateUserProfileFields] = useUpdateUserProfileFieldsMutation();


  const dispatch = useDispatch();

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleSave = async () => {
    const obj: Record<string, any> = {
      [apiFieldName]: newValue,
    };
    
    if (apiFieldName === "old_password") {
      obj.new_password = newPasswordValue; // Указываете значение нового пароля
    }
    const response = await updateUserProfileFields({ apiEnd, obj }).unwrap();
    console.log("PATH answer: ", response);

    const objResponse: ProfileState = {
      ...profileData,
      id: response.id,
      username: response.username,
      email: response.email,
      firstName: response.first_name,
      lastName: response.last_name,
      birthDate: response.birth_date,
      imageUrl: "http://"+response.user_img_200x200_url,
      
    };
  
    dispatch(setProfile(objResponse));
    console.log(profileData.username);
    handleCloseModal();
  };

  return (
    <div className="profile-field">
      <div className="profile-field__wrapper" onClick={handleOpenModal}>
        <div className="profile-field__data">
          <div>{label}</div>
          <div>{field}</div>
        </div>
        <div className="profile__line"></div>
      </div>
    
      <Modal
        isOpen={isModalOpen}
        onRequestClose={handleCloseModal}
        className="profile-modal__modal"
        overlayClassName="profile-modal__overlay"
      >
        <h2 className='profile-edit__title'> {label}</h2>
        <div className="profile-field__modal-content">
          <input
            type={label === 'Password' ? 'password' : 'text'}
            value={newValue}
            onChange={(e) => setNewValue(e.target.value)}
            className="profile-field__input"
            placeholder={label === 'Password' ? 'Enter previous password' : 'New value'}
          />
          {label === 'Password' && (
            <input
            type="password"
            value={newPasswordValue}
            onChange={(e) => setNewPasswordValue(e.target.value)}
            className="profile-field__input"
            placeholder="Enter new password" // Можете добавить текст подсказки для второго поля
          />
          )}
        </div>
        <div className="edit__button">
            <button className="button-cancel" onClick={handleCloseModal}>Cancel </button>
            <button className="button-save" onClick={handleSave}>Okey</button>
        </div>

      </Modal>
    </div>
  );
};

export default ProfileField;
