import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export interface CreateChatData {
  username: string;
}

export const createChatApi = createApi({
  reducerPath: 'createChatApi',
  baseQuery: fetchBaseQuery({
    baseUrl: '/api/v1/chat',
    prepareHeaders: (headers, { getState }) => {
      const token = localStorage.getItem('access_token');
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    createPrivateChat: builder.mutation<void, CreateChatData>({
      query: (data) => ({
        url: '/private',
        method: 'POST',
        body: data,
      }),
    }),
  }),
});

export const { useCreatePrivateChatMutation } = createChatApi;
