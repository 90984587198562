import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { PrivateChat } from '../slices/chatListSlice';
import { Message } from '../slices/messagesListSlice';


export const chatApi = createApi({
  reducerPath: 'chatApi',
  baseQuery: fetchBaseQuery({
    baseUrl: '/api/v1/chat',
    prepareHeaders: (headers) => {
      const token = localStorage.getItem('access_token');
      
      if (token) {
        headers.set('Authorization', `Bearer ${token}`); 
      }
      
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getPrivateChats: builder.query<PrivateChat[], { pageIndex: number; pageSize: number }>( {
      query: ({ pageIndex, pageSize }) => ({
        url: '/private', 
        params: {
          pageIndex,
          pageSize,
        }
      }),
    }),
    deleteChat: builder.mutation<void, {id:string}>( {
      query: ({id}) => ({
        url: '/'+id, 
        method: "DELETE"
      }),
    }),
    getChatMessages: builder.query<Message[], { chatId: string }>( {
      query: ({ chatId }) => ({
        url: `/${chatId}/message`, 
      }),
    }),
    
  }),
});

export const { useGetPrivateChatsQuery, useDeleteChatMutation, useGetChatMessagesQuery } = chatApi;
