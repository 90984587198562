import React from "react";
import "./inputField.css";

interface InputFieldProps {
  id: string;
  name: string; // Добавлено для обработки изменений
  label: string;
  type: string;
  placeholder: string;
  value: string; // Тип для значения
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void; // Обработчик изменений
}

const InputField: React.FC<InputFieldProps> = ({ id, name, label, type, placeholder, value, onChange }) => {
  return (
    <div className={`input-field ${id}-input`}>
      <label
        htmlFor={id}
        className={`input-field__label ${id}-input__label register-label`}
        aria-labelledby={id}
      >
        {label} :
      </label>
      <div className={`input-field__container ${id}-input__container register-wrapper`}>
        <input
          type={type}
          id={id}
          name={name} // Добавлено для передачи имени поля
          className={`input-field__field ${id}-input__field register-input`}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          required 
        />
      </div>
    </div>
  );
};

export default InputField;
