import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ProfileState {
  id: number | null;
  username: string;
  email: string;
  firstName: string;
  lastName: string;
  birthDate: string;
  imageUrl: string;
  isActive: boolean;
  lastActiveDate: string;
  phoneNumber: string;
}

const initialState: ProfileState = {
  id: null,
  username: '',
  email: '',
  firstName: '',
  lastName: '',
  birthDate: '',
  imageUrl: '',
  isActive: false,
  lastActiveDate: '',
  phoneNumber: '',
};


const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setProfile: (state, action: PayloadAction<ProfileState>) => {
      state.id = action.payload.id;
      state.username = action.payload.username;
      state.email = action.payload.email;
      state.firstName = action.payload.firstName;
      state.lastName = action.payload.lastName;
      state.birthDate = action.payload.birthDate;
      state.imageUrl = action.payload.imageUrl;
      state.isActive = action.payload.isActive;
      state.lastActiveDate = action.payload.lastActiveDate;
      state.phoneNumber = action.payload.phoneNumber;
    },

    clearProfile: (state) => {
      state.id = null;
      state.username = '';
      state.email = '';
      state.firstName = '';
      state.lastName = '';
      state.birthDate = '';
      state.imageUrl = '';
      state.isActive = false;
      state.lastActiveDate = '';
      state.phoneNumber = '';
    },

    setUserName: (state, action: PayloadAction<ProfileState>) => {
      state.username = action.payload.username;
    },
    setEmail: (state, action: PayloadAction<ProfileState>) => {
      state.email = action.payload.email;
    },
    setFirstName: (state, action: PayloadAction<ProfileState>) => {
      state.firstName = action.payload.firstName;
    },
    setLastName: (state, action: PayloadAction<ProfileState>) => {
      state.lastName = action.payload.lastName;
    },
    setBirthDate: (state, action: PayloadAction<ProfileState>) => {
      state.birthDate = action.payload.birthDate;
    },
    setImage: (state, action: PayloadAction<ProfileState>) => {
      state.imageUrl = action.payload.imageUrl;
    },
    setPhoneNumber: (state, action: PayloadAction<ProfileState>) => {
      state.phoneNumber = action.payload.phoneNumber;
    },
  },
});


export const { setProfile, clearProfile } = profileSlice.actions;
export default profileSlice.reducer;


export const selectProfile = (state: { profile: ProfileState }) => state.profile;
