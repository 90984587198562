import React, { useEffect, useState } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from './store';
import { initializeAuth } from './thunks/authThunks';


import LoginPage from './pages/LoginPage/LoginPage';
import RegisterPage from './pages/RegisterPage/RegisterPage';
import HomePage from './pages/HomePage/HomePage';

const App: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const [isAuthInitialized, setIsAuthInitialized] = useState(false); 
 

  useEffect(() => {
    const initializeAuthentication = async () => {
      await dispatch(initializeAuth()); 
      setIsAuthInitialized(true); 
    };

    initializeAuthentication();

    
    

  }, [dispatch]);
  
 
  if (!isAuthInitialized) {

    return <div>Загрузка приложения...</div>;
  }

  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/register" element={<RegisterPage />} />
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
};

export default App;
