
import React, { useState } from "react"; 
import { useDispatch } from "react-redux"; // Импортируем dispatch
import "./RegisterPage.css"; 
import InputField from "../../components/InputField/InputField";
import { FormValidation } from "./FormValidation";
import { useRegisterMutation } from "../../services/registerApi";
import { UserRegisterData } from "../../services/registerApi";
import { clearTokens } from "../../slices/authSlice"; 
import { Link, useNavigate } from "react-router-dom";

const RegisterPage: React.FC = () => {

    const [formData, setFormData] = useState<UserRegisterData>({
        username: "",
        email: "",
        password: "",
        password_confirmation: "",
        first_name: "",
        last_name: "",
        birth_date: "", 
        phone_number: "",
    });
  
    const [register, { isLoading, isError, data, error }] = useRegisterMutation();
    const dispatch = useDispatch(); 
    const navigate = useNavigate();
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        if (name === "birth_date" && value) {
            const date = new Date(value);
            setFormData({ ...formData, [name]: date.toISOString() });
        } else  {
            setFormData({ ...formData, [name]: value });
        }
      
    };

    const handleSignup = async (e: React.FormEvent) => {
        e.preventDefault();
       
            const {validateForm} = FormValidation(formData);
            const isValid = validateForm();
            if(isValid){
              try {
                await register(formData).unwrap();
                console.log("Registration successful!", data);
                dispatch(clearTokens()); // Очищаем токены 
                navigate("/login");
              } catch (err) {
                console.error("Registration failed:", err);
            }
          }
            else console.log("Invalid form data");
            

        }
    

    return (
        <div className="register">
            <div className="container-register">
                <div className="register__left">
                    <img src="Dummy_Logo.png" alt="Logo" className="register__logo" />
                    <h1 className="register__caption">Sign up into your account</h1>
                    <form className="register-form" onSubmit={handleSignup}>
                        <InputField
                            id="username"
                            name="username"
                            label="Username"
                            type="text"
                            placeholder="Enter your username.."
                            value={formData.username}
                            onChange={handleChange} 
                        />
                        <InputField
                            id="email2"
                            name="email"
                            label="Email Id"
                            type="email"
                            placeholder="Enter your email.."
                            value={formData.email}
                            onChange={handleChange} 
                        />
                        <InputField
                            id="firstName"
                            name="first_name" 
                            label="First Name"
                            type="text"
                            placeholder="Enter your name.."
                            value={formData.first_name}
                            onChange={handleChange} 
                        />
                        <InputField
                            id="lastName"
                            name="last_name" 
                            label="Last Name"
                            type="text"
                            placeholder="Enter your last name.."
                            value={formData.last_name}
                            onChange={handleChange} 
                        />
                        <InputField
                            id="mobileNumber"
                            name="phone_number" 
                            label="Mobile No."
                            type="tel"
                            placeholder="Enter your mobile number.."
                            value={formData.phone_number}
                            onChange={handleChange} 
                        />
                        <InputField
                            id="password2"
                            name="password"
                            label="Password"
                            type="password"
                            placeholder="Enter your password.."
                            value={formData.password}
                            onChange={handleChange} 
                        />
                        <InputField
                            id="confirmPassword"
                            name="password_confirmation" 
                            label="Confirm Password"
                            type="password"
                            placeholder="Re-enter your password.."
                            value={formData.password_confirmation}
                            onChange={handleChange} 
                        />
                        <InputField
                            id="birth_date"
                            name="birth_date"
                            label="Birth date"
                            type="date"
                            placeholder="Enter your birth date.."
                            value={formData.birth_date ? formData.birth_date.split("T")[0] : ""} //костыль внеплановый
                            onChange={handleChange}
                        />
                        <button type="submit" className="register-form__button">Sign Up</button>
                    </form>
                </div>
                <div className="register__right">
                    {/*  */}
                </div>
            </div>
        </div>
    );
};

export default RegisterPage;
