import React from 'react';
import "./friendsNav.css"

const FriendsNav: React.FC = () => {
  return (
    <div className="friend">
      <div className="friend__wrapper">
        <div className="friend-box">
          <img src="Friends.png" alt="Friends icon" className="friend-icon" />
          <div className="friend-box__title">Friends</div>
          <div className="dash"></div>
        </div>
        <div className="friend__pages">
          <div className="friend-page">Online</div>
          <div className="friend-page">All</div>
          <div className="friend-page">Pending</div>
        </div>
        <div className="number__pending">
          <span>2</span>
        </div>
        <button className="friends__button">Add Friend</button>
      </div>
    </div>
  );
};

export default FriendsNav;