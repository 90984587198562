import { AppDispatch } from '../store'; 
import { refreshApi } from '../services/refreshApi'; 
import { setTokens, clearTokens } from '../slices/authSlice'; 
import { clearProfile } from '../slices/profileSlice'; // Импортируем экшен для очистки профиля
import WebSocketManager from '../slices/WebSocketManager';

export const initializeAuth = () => async (dispatch: AppDispatch) => {
  
  try {
    
    const refreshToken = localStorage.getItem('refresh_token');

    if (!refreshToken) {
      console.warn('Refresh token отсутствует');
      dispatch(clearTokens());
      return;
    }

    const result = await dispatch(
      refreshApi.endpoints.refreshTokens.initiate({ refresh_token: refreshToken })
    ).unwrap();
    
    console.log('Успешное обновление токенов:', result);
    dispatch(
      setTokens({
        accessToken: result.access_token,
        accessTokenExpiration: result.access_token_expiration,
        refreshToken: result.refresh_token,
        refreshTokenExpiration: result.refresh_token_expiration,
      })
    );
    
  } catch (error: any) {

    console.error('Ошибка при обновлении токена:', error);
    dispatch(clearTokens());
    if (error?.status === 401 || error?.status === 403) {
      console.warn('Токен недействителен или истёк. Пользователь будет разлогинен.');
    }
  }
};
