import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface Message {
    sender: Sender
    attachments: Attachment[] | null
    reactions: Reaction[] | null
    message_id:number
    chat_id: number
    message_text: string
    message_date: string
    forward_id: number | null
    forward_message: string | null
    is_changed: boolean
    is_audio: boolean
    is_pinned: boolean

}
export interface Sender {
    id: number
    first_name: string
    last_name: string
    image_url: string
    is_active: boolean
}
export interface Attachment {
    name: string
    attachment_type: string
    attachment_id: number
    attachment_url: string
    attachment_image_small_url: string
    attachment_image_big_url: string
    creator_id: number
    creation_date: string
}
export interface Reaction {
    user_id: number
    reaction_id: number

}

export interface MessageState {
    messages: Message[];
    chat_id: string; 
    user_id: string | null;
    
}

const initialState: MessageState = {
    messages: [],
    chat_id:"" ,
    user_id: null
  };


const messageSlice = createSlice({
    name: "message",
    initialState,
    reducers: {
      // Устанавливает список сообщений
      setMessageList: (state, action: PayloadAction<Message[]>) => {
        state.messages = action.payload;
      },
      setChatID: (state, action: PayloadAction<string>) => {
        state.chat_id = action.payload
      },
      setUserID: (state, action: PayloadAction<string>) => {
        state.user_id = action.payload
      },
  
      // Добавляет новое сообщение
      addMessage: (state, action: PayloadAction<Message>) => {
        state.messages.push(action.payload);
      },
  
      // Удаляет сообщение по его ID
      deleteMessage: (state, action: PayloadAction<number>) => {
        state.messages = state.messages.filter(
          (message) => message.message_id !== action.payload
        );
      },
      clearList: (state) => {
        return initialState
      },
    },
  });
export const {setMessageList, addMessage, deleteMessage, clearList, setChatID, setUserID} = messageSlice.actions
export default messageSlice.reducer